.l1mfwas2{line-clamp:var(--lines, 1);-webkit-line-clamp:var(--lines, 1);word-break:break-all;overflow:hidden;display:-webkit-box;-webkit-box-orient:vertical;}
.s6ml242.s6ml242{font-size:12px;}
.bta8dtt.bta8dtt{font-size:14px;font-weight:600;}
.l37ct4v.l37ct4v{font-size:16px;font-weight:light;}
.ls167e1.ls167e1{font-size:16px;line-height:2rem;font-weight:600;}
.d10godwa{font-size:18px;}
.mn81cx4.mn81cx4{font-size:20px;font-weight:700;}
.t1fp78hw.t1fp78hw{font-size:24px;font-weight:700;}
.b1ylj9yl.b1ylj9yl{font-size:34px;line-height:30px;font-weight:700;}

.e1txmykp.i11uskxf{cursor:pointer;}
