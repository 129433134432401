









.sfzsxxu{background:#DAF2FC;}
.ihjk8hg{padding:0.5rem 0.75rem;}.ihjk8hg:hover{background:#DAF2FC;}.ihjk8hg:focus-visible{z-index:1;}.ihjk8hg[disabled]{pointer-events:none;color:#C2C2C2;}
.dnfkbce{position:relative;--dropdown-gap:0.5rem;}.dnfkbce.g1jp3guu{--dropdown-gap:0;}.dnfkbce .t1pcqmmx{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;}.dnfkbce .ce3glzj{position:absolute;left:0;right:0;height:var(--dropdown-gap);bottom:calc(var(--dropdown-gap) * -1);}.dnfkbce .ce3glzj.twmj354{top:calc(var(--dropdown-gap) * -1);bottom:unset;}.dnfkbce .c1pccx7v{visibility:hidden;left:0;opacity:0;-webkit-transition:visibility 0s linear 0.125s,opacity 0.125s linear;transition:visibility 0s linear 0.125s,opacity 0.125s linear;margin-top:var(--dropdown-gap);position:absolute;overflow-y:auto;overflow-x:hidden;top:100%;border-width:1px;border-color:#00CCF8;background:#ffffff;z-index:50;border-radius:0.75rem;border:1px solid #d7d7d7;box-shadow:2px 2px 6px 2px rgba(0, 0, 0, 0.1);}.dnfkbce .c1pccx7v.s1glbsea{visibility:visible;opacity:1;transition-delay:0s;}.dnfkbce .c1pccx7v.f1rpwlcx{min-width:100%;}.dnfkbce .c1pccx7v.lnidnex{right:unset;left:0;}.dnfkbce .c1pccx7v.rgx5qij{right:0;left:unset;}.dnfkbce .c1pccx7v.twmj354{top:initial;margin-top:0;margin-bottom:var(--dropdown-gap);bottom:100%;}
