









.a1gl531x{border-left-width:8px;font-size:1rem;padding:16px 24px;border-radius:4px;gap:12px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:space-between;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;white-space:nowrap;background:var(--alert-content-color, #DAF2FC);color:var(--alert-text-color, #43CCF8);-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.a1gl531x .c2w0b0d{gap:12px;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:1;-ms-flex:1;flex:1;}@media (min-width: 500px){.a1gl531x .c2w0b0d{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}}.a1gl531x .c2w0b0d.v1g4bnzk{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}.a1gl531x .t13twj06{font-weight:600;gap:12px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:16px;line-height:24px;}.a1gl531x .c1b49vvw{width:100%;font-weight:600;font-size:16px;white-space:normal;line-height:24px;}.a1gl531x .bpwwu3r{color:#ffffff;}.a1gl531x .i2ouoq2{font-size:inherit;}.a1gl531x.s7z7tj9{--alert-text-color:#037602;--alert-content-color:#8fe17b;}.a1gl531x.w1ps36zr{--alert-text-color:#FACA2C;--alert-content-color:#ffbc6b;}.a1gl531x.e1vu72tn{--alert-text-color:#c12121;--alert-content-color:#f47575;}
